import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Button from '../../../Button/Button';
import '../../../Button/Button.css'
import './StepTwo.css'
import SimpleContainer from '../../../SimpleContainer/SimpleContainer';
import StatuteParagraph from '../../../StatuteParagraph/StatuteParagraph';
import { Trans, useTranslation } from 'react-i18next';


const StepTwo = ({ setNextDisabled, setPrepareClientOption }) => {

  const [showBase, setShowBase] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false)
  const { t, i18n } = useTranslation('translation')

  const handleClickBase = () => {
    setShowBase(!showBase)
  }

  const handleClickAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  function handleChange(name) {
    setPrepareClientOption(name)
    setNextDisabled(false)
  }

  useEffect(() => {
    setNextDisabled(true)
  }, [])

  return (
    <div className="component-step step-two">
      <div className="component-step-inner">
        <p><Trans i18nKey={"page-profile.view-prepare.prepare-step-two.options-desc"}>Wybierz jedną z dwóch opcji przygotowania systemu przed optymalizacją – pełną lub podstawową.
          W celu osiągnięcia najlepszych wyników <span style={{ textDecoration: 'underline' }}>zalecany jest bardziej rozbudowany wariant.</span> Instrukcja zostanie wysłana na Twój e-mail.</Trans></p>
        <div className="options-brief-container">
          <SimpleContainer>
            <div className="option-wrapper advanced">
              <p style={{ color: 'rgb(0, 224, 255)' }}>{t("page-profile.view-prepare.prepare-step-two.full-label")}</p>
              <ul>
                <li>{t("page-profile.view-prepare.prepare-step-two.full-require-one")}</li>
                <li>{t("page-profile.view-prepare.prepare-step-two.full-require-two")}</li>
                <li style={{ marginBottom: '0.5rem' }}>{t("page-profile.view-prepare.prepare-step-two.full-require-three")}</li>
                <Button onClick={handleClickAdvanced} className='button try black small' >{t("page-profile.view-prepare.prepare-step-two.show-instruction-button")}</Button>
              </ul>
            </div>
          </SimpleContainer>
          <SimpleContainer>
            <div className="option-wrapper base">
              <p style={{ color: 'rgb(150 151 151)' }}>{t("page-profile.view-prepare.prepare-step-two.base-label")}</p>
              <ul>
                <li style={{ marginBottom: '0.5rem' }}>{t("page-profile.view-prepare.prepare-step-two.base-require-one")}</li>
                <li style={{ marginBottom: '0.5rem' }}>{t("page-profile.view-prepare.prepare-step-two.base-require-two")}</li>
                <Button onClick={handleClickBase} className='button try gray small'>{t("page-profile.view-prepare.prepare-step-two.show-instruction-button")}</Button>
              </ul>
            </div>
          </SimpleContainer>
        </div>
        <div className="choose-option-container">
          <p style={{ color: 'white', marginBottom: '1', display: 'inline-block' }}>{t("page-profile.view-prepare.prepare-step-two.chosing-prepare-label")}</p>
          <div className="options-container">
            <div className="option-wrapper">
              <input type="radio" name="select-prepare" id="option-1" onChange={() => {
                handleChange('rozszerzone')
              }} />
              <label htmlFor="option-1" className="option option-1 preferable">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-two.advanced")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-prepare" id="option-2" onChange={() => {
                handleChange('podstawowe')
              }} />
              <label htmlFor="option-2" className="option option-2">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-two.base")}</span>
              </label>
            </div>
          </div>
        </div>

        <Modal show={showAdvanced} onHide={handleClickAdvanced}>
          <Modal.Header closeButton>
            <Modal.Title>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.full-system-preparation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 0, height: '80vh' }}>
            <iframe
              src={`/instructions/advanced-${i18n.language}.html`}
              title={t('page-profile.view-prepare.prepare-step-two.modal-advanced.full-system-preparation')}
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClickAdvanced}>
              {t("nav-buttons.close")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showBase} onHide={handleClickBase}>
          <Modal.Header closeButton>
            <Modal.Title>{t('page-profile.view-prepare.prepare-step-two.modal-base.basic-system-preparation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 0, height: '80vh' }}>
            <iframe
              src={`/instructions/base-${i18n.language}.html`}
              title={t('page-profile.view-prepare.prepare-step-two.modal-base.basic-system-preparation')}
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClickBase}>
              {t("nav-buttons.close")}
            </Button>
          </Modal.Footer>
        </Modal>

      </div >
    </div >
  )
}

export default StepTwo