import React from 'react'
import SimpleContainer from '../../../SimpleContainer/SimpleContainer'
import './StepSix.css'
import { useTranslation, Trans } from 'react-i18next'

const StepSix = () => {
  const { t } = useTranslation('translation')
  return (
    <div className="component-step step-six">
      <div className="component-step-inner">
        <SimpleContainer>
          <h4>{t("page-profile.view-prepare.prepare-step-six.main-label")}</h4>
          <ol className='product-box-ol'>
            <li><Trans i18nKey={"page-profile.view-prepare.prepare-step-six.list-one"}><span style={{ color: 'rgb(0,224,255)' }}>Przygotuj system </span>według instrukcji wysłanej na e-mail.</Trans></li>
            <li><Trans i18nKey={"page-profile.view-prepare.prepare-step-six.list-two"}>Jeśli jeszcze tego nie zrobiłeś, <span style={{ color: 'rgb(0,224,255)' }}>zarezerwuj termin</span>.</Trans></li>
            <li><Trans i18nKey={"page-profile.view-prepare.prepare-step-six.list-three"}>Podaj nam <span style={{ color: 'rgb(0,224,255)' }}>dane dostępu do programu AnyDesk</span> najpóźniej 15 minut przed terminem.</Trans></li>
          </ol>
          <p style={{ fontSize: '0.9rem', marginBottom: '0', marginTop: '0.5rem', color: 'rgb(0, 224, 255)' }}>{t("page-profile.view-prepare.prepare-step-six.reminder-msg")}</p>
          <p style={{ fontSize: '0.9rem', marginBottom: '0', marginTop: '0.5rem', color: 'rgb(0, 224, 255)' }}>{t("page-profile.view-prepare.prepare-step-six.info-msg")}</p>
        </SimpleContainer>
      </div>
    </div >
  )
}

export default StepSix