import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PrepareStep from '../PrepareStep/PrepareStep';
import Button from '../../Button/Button'
import { Alert, Spinner, Modal, Form, InputGroup } from 'react-bootstrap'
import { useAuth } from '../../../context/AuthContext'
import './ViewPrepareStart.css'
import sendMail from '../../../utils/sendMail';
import supabase from '../../../data/supabase';
import SimpleContainer from '../../SimpleContainer/SimpleContainer';
import visibleOffIcon from '../../../assets/img/icons/visibility_off_black_36dp.svg'
import visibleOnIcon from '../../../assets/img/icons/visibility_black_36dp.svg'
import StatuteParagraph from '../../StatuteParagraph/StatuteParagraph';
import moment from 'moment';
import 'moment/locale/pl'
import 'moment-timezone';
import { useDatabasePageContext } from '../../../context/DatabasePageContext';
import { Trans, useTranslation } from 'react-i18next';

const ViewPrepareStart = () => {
    const location = useLocation()
    const { id, from, name } = location.state
    const { currentUser } = useAuth()
    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [complete, setComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [nextDisabled, setNextDisabled] = useState(true)
    const [showPassword, setShowPassword] = useState(false);
    const [secondsRemaining, setSecondsRemaining] = useState()
    const [valid, setValid] = useState(true);
    const adIdRef = useRef()
    const adPasswordRef = useRef()
    const rodoRef = useRef()
    const accessRef = useRef()
    const [showInstruction, setShowInstruction] = useState(false);
    const intervalIdRef = useRef(null);
    const { timezone } = useDatabasePageContext()
    const { t, i18n } = useTranslation('translation')

    const handleClickInstruction = () => {
        setShowInstruction(!showInstruction)
    }
    ////////////////////////////////////////////////////////////////////////////

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate();

    async function handleNext() {
        setError('')
        setErrorSuccess('')
        setLoading(true)

        if (!complete) {
            if (!(adIdRef?.current?.value && adPasswordRef?.current?.value)) {
                setError(t("forms.error.fill-data-error"))
            }
            else {
                try {
                    const { data: anydeskData, error: anydeskError } = await supabase
                        .from('anydesk_data')
                        .insert({
                            'ad_number': adIdRef?.current?.value,
                            'ad_password': adPasswordRef?.current?.value
                        })
                        .select()
                        .single()

                    if (anydeskError) {
                        setError(t("errors.server-error-occured"))
                        return;
                    }
                    else {
                        const { data: bookedServiceData, error: bookedServiceError } = await supabase
                            .from('booked_services')
                            .update({
                                'ad_id': anydeskData?.id,
                                'status': 'ready'
                            })
                            .eq('id', id)
                            .select()
                            .single()

                        if (bookedServiceError) {
                            setError(t("errors.server-error-occured"))
                            return;
                        } else {

                            let orderId = bookedServiceData?.order_id
                            let date_moment = null
                            let bookedDate = null
                            let bookedTime = null
                            if ((bookedServiceData?.booked_date) && (bookedServiceData?.booked_date !== 'later')) {
                                date_moment = moment.tz(`${bookedServiceData?.booked_date}`, timezone)
                                bookedDate = date_moment.format('DD.MM.YYYY')
                                bookedTime = date_moment.format('HH:mm')
                            }

                            await sendMail({
                                author: 'noreply', receiver: currentUser?.email, subject: t("page-profile.view-prepare-start.email-subject"), service_name: name, order_number: orderId, booked_date: bookedDate, booked_time: bookedTime, timezone: timezone, template: t("page-profile.view-prepare-start.email-template"), language: i18n?.language
                            })
                        }
                    }
                } catch (error) {
                    setError(error.message)
                }
            }
        } else {
            navigate('/konto/moje-uslugi/')
        }

        setComplete(true)
        setLoading(false)

        let remainingSeconds = 5;
        setSecondsRemaining(remainingSeconds)
        // Rozpoczęcie odliczania co sekundę
        intervalIdRef.current = setInterval(() => {
            remainingSeconds--;
            setSecondsRemaining(remainingSeconds);

            if (remainingSeconds <= 0) {
                clearInterval(intervalIdRef.current);
                navigate('/konto/moje-uslugi/');
            }
        }, 1000);
        setLoading(false)
    }

    // Używamy useEffect do czyszczenia interwału, gdy komponent zostaje odmontowany
    useEffect(() => {
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);


    async function handleSubmit(e) {
        e.preventDefault()
    }

    async function handleBack() {
        setError('')
        setErrorSuccess('')
        navigate(-1)
    }

    function checkIfEmpty() {
        if (!(adIdRef?.current?.value && adPasswordRef?.current?.value && accessRef?.current?.checked && rodoRef?.current?.checked && checkIfValid(adIdRef?.current?.value))) {
            setNextDisabled(true)
        }
        else {
            setNextDisabled(false)
        }

    }

    const checkIfValid = (value) => {
        // przekształcenie do systemu dziesiętnego
        const numericValue = parseInt(value, 10);
        // NaN = Not a Number, czyli sprawdzamy czy numericValue jest liczbą
        return !isNaN(numericValue);
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const isValid = checkIfValid(inputValue);
        setValid(isValid);
    };

    return (
        <>
            <div className="view-prepare-start-container">
                <div className="view-prepare-start-wrapper">
                    <div className="header-container">
                        <div className="counter-wrapper">
                            <h4>{t("page-profile.view-prepare-start.anydesk-data")}</h4>
                            {error ? <Alert variant="danger">{error}</Alert> : <h5>{t("page-profile.view-prepare-start.service-number")} #{id}</h5>}
                        </div>
                    </div>
                    <div className={(loading || complete) ? "step-view-container center" : "step-view-container"}>
                        {
                            complete ? (
                                error ? <h4>{t("errors.server-error-occured")}</h4> :
                                    <div className="step-view-text-container">
                                        <h4>{t("page-profile.view-prepare-start.prepare-success")}</h4>
                                        <p>{t("messages.returning-in")} {secondsRemaining}...</p>
                                    </div>

                            ) : (
                                loading ?
                                    <div className="spinner-container">
                                        <Spinner animation="border" className='loading-spinner' />
                                    </div>
                                    :
                                    <PrepareStep>
                                        <SimpleContainer>
                                            <div className="component-step-start">
                                                <div className="component-step-start-inner">
                                                    <div className="component-anydesk-data-form">
                                                        <div className="component-anydesk-data-form-inner">
                                                            <Form className="form prepare-start-form" onSubmit={handleSubmit} noValidate>
                                                                {error && <Alert variant="danger">{error}</Alert>}
                                                                {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                                                                <Form.Group className="form-anydesk-data-group">
                                                                    <Form.Label>{t("page-profile.view-prepare-start.anydesk-id-label")}</Form.Label>
                                                                    <Form.Control type="number" ref={adIdRef} required placeholder={t("page-profile.view-prepare-start.anydesk-id-placeholder")} id={`anydesk_id_svc_${id}`} isValid={valid} maxLength={15}
                                                                        isInvalid={!valid}
                                                                        onChange={(event) => {
                                                                            handleInputChange(event)
                                                                            checkIfEmpty()
                                                                        }} />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {t("page-profile.view-prepare-start.anydesk-id-feedback")}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group className="form-anydesk-data-group">
                                                                    <Form.Label>{t("page-profile.view-prepare-start.anydesk-pw-label")}</Form.Label>
                                                                    <InputGroup>
                                                                        <Form.Control type={showPassword ? "text" : "password"} ref={adPasswordRef} required placeholder={t("page-profile.view-prepare-start.anydesk-pw-placeholder")} id={`anydesk_password_svc_${id}`} maxLength={45}
                                                                            onChange={(event) => {
                                                                                checkIfEmpty()
                                                                            }}
                                                                        />
                                                                        <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={togglePasswordVisibility}>
                                                                            {showPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                                                        </span>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                <Button type='button' onClick={handleClickInstruction} className='button try black small' >{t("page-profile.view-prepare-start.show-instruction-button")}</Button>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <div className="choose-option-container">
                                                        <div className="options-container">
                                                            <div className="option-wrapper">
                                                                <input type="checkbox" name="select-accept-rodo" id="select-accept-rodo" ref={rodoRef} onChange={(event) => {
                                                                    checkIfEmpty()
                                                                }} />
                                                                <label htmlFor="select-accept-rodo" className="option option-1">
                                                                    <div className="dot-wrapper">
                                                                        <div className="dot"></div>
                                                                    </div>
                                                                    <span>{t("page-profile.view-prepare-start.accept-rodo")}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="options-container">
                                                            <div className="option-wrapper">
                                                                <input type="checkbox" name="select-accept-access" id="select-accept-access" ref={accessRef} onChange={(event) => {
                                                                    checkIfEmpty()
                                                                }} />
                                                                <label htmlFor="select-accept-access" className="option option-2">
                                                                    <div className="dot-wrapper">
                                                                        <div className="dot"></div>
                                                                    </div>
                                                                    <span>{t("page-profile.view-prepare-start.accept-access")}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </SimpleContainer>
                                    </PrepareStep>
                            )
                        }
                    </div>
                    <Modal show={showInstruction} onHide={handleClickInstruction}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('page-profile.view-prepare-start.modal-title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: 0, height: '80vh' }}>
                            <iframe
                                src={`/instructions/anydesk-${i18n.language}.html`}
                                title={t('page-profile.view-prepare-start.anydesk-preparation')}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClickInstruction}>
                                {t('nav-buttons.close')}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="bottom-nav-container">
                        <div className="bottom-nav-left">
                            <Button className='button try medium' onClick={handleBack} disabled={loading ? loading : complete}>
                                {t("nav-buttons.return")}
                            </Button>
                        </div>
                        <div className="bottom-nav-right">
                            <Button className='button try medium' onClick={handleNext} disabled={loading ? loading : nextDisabled}>
                                {t("nav-buttons.end")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewPrepareStart